import React from "react";
import TeamProfile from "../../src/components/TeamProfile/TeamProfile.js";
import Seo from "../components/SEO/SEO";
import { StaticImage } from "gatsby-plugin-image";
import SimpleMdx from "../layout/simpleMdx";

const AboutPage = () => {
    return (
        <SimpleMdx>
            <h1>About MinervaTools</h1>

            <Seo
                title={"About"}
                seoTitle={"About MinervaTools"}
                description="Meet the Team behind MinervaTools and learn more about what motivates us!"
            />

            <h2>Meet the Team</h2>

            <div style={{ margin: "0 auto", width: "300px" }}>
                <TeamProfile
                    name="Kevin Kandlbinder"
                    image={
                        <StaticImage
                            src="../../content/images/team/kevin-kandlbinder.jpg"
                            alt="Kevin Kandlbinder Portrait"
                            width={300}
                        />
                    }
                    role="Lead Developer"
                    email="kevin.k@minervatools.net"
                    website="https://kevink.dev"
                />
            </div>

            <h2>Wait, just one?</h2>

            <p>
                Yeah... I'm currently developing MinervaTools completely on my
                own with support from my friends over at "CommandBlock" who are
                way more experienced in the needs of Minecraft builders than I
                am.
            </p>
            <p>
                As the project progresses I'll probably get some co-developers
                to help speed up the release cycles.
            </p>

            <h2>Talk to me!</h2>

            <p>
                If you have any questions regarding MinervaTools feel free to
                send me an email and I'll get back to you ASAP!
            </p>
        </SimpleMdx>
    );
};

export default AboutPage;
