import React from "react";

import * as styles from "./TeamProfile.module.scss";

const TeamProfile = ({ name, role, email, website, image }) => {
    return (
        <div className={styles.profile}>
            <div className={styles.imageWrapper}>{image}</div>
            <div className={styles.details}>
                <span className={styles.name}>{name}</span>
                <span className={styles.role}>{role}</span>
                <a className={styles.email} href={"mailto:" + email}>
                    {email}
                </a>
                <a className={styles.website} href={website}>
                    {website}
                </a>
            </div>
        </div>
    );
};

export default TeamProfile;
